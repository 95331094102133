<template>
  <div class="container"  v-wechat-title="$route.meta.title=brandInfo.app_name" >
    <div class="header">
      <!--  -->
      <div class="header-avatar">
        <van-image class="header-avatar-img" :src="brandInfo.app_avatar" />
      </div>
    </div>
    <div class="form">
      <van-field
        v-model="phone"
        class="form-field"
        clearable
        type="tel"
        label="手机号码"
        placeholder="请输入手机号码"
      />
      <van-field
        v-model="vcode"
        class="form-field"
        center
        :maxlength='6'
        clearable
        type="number"
        placeholder="请输入短信验证码"
      >
        <!-- label="短信验证码" -->
        <van-button slot="button" size="small" :disabled="!!vcodeSend" type="primary" @click="sendCode">{{ vcodeText }}</van-button>
      </van-field>
      <div class="form-field van-cell van-cell--center van-field">
        <!-- <van-button type="primary" size="large" @click="onSubmit">绑定</van-button> -->
        <van-button type="primary" block @click="onSubmit">绑定</van-button>
      </div>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Field } from 'vant'
import { isPhone, isEmpty } from '@/common/validate'
import {
  saveToken
} from '@/utils/cache'

var vcodeTimer
export default {
  name: 'BindPhone',
  components: {
    [Field.name]: Field
  },
  mixins: [PageMixin],
  data() {
    return {
      brandInfo: {},
      phone: '',
      vcode: '',
      vcodeSend: 0, // 0获取验证码,1验证码倒计时,
      vcodeText: '获取验证码'
    }
  },
  created() {
    this.querybrandIndex()
  },
  methods: {
    querybrandIndex() {
      this.$api.brand_index().then(res => {
        const info = res.data
        this.brandInfo = info
      })
    },
    sendCode() {
      if (!isPhone(this.phone)) {
        this.$toast('手机号格式错误')
        return
      }
      if (this.vcodeSend === 1) {
        return
      }
      clearInterval(vcodeTimer)
      let time = 60
      vcodeTimer = setInterval(() => {
        if (time <= 0) {
          this.vcodeSend = 0
          this.vcodeText = '获取验证码'
        } else {
          this.vcodeSend = 1
          this.vcodeText = `已发送(${time}s)`
          time--
        }
      }, 1000)
      this.$api.auth_send_text_msg({ cellphone: this.phone })
        .then(res => {
          this.$toast.success('验证码已发送')
        })
        .catch(err => {
          this.$toast.fail('短信余额不足')
          console.error(err)
        })
    },
    onSubmit() {
      if (!isPhone(this.phone)) {
        this.$toast('手机号格式错误')
        return
      }
      if (isEmpty(this.vcode)) {
        this.$toast('请输入正确的验证码')
        return
      }
      this.$toast.loading({
        mask: true,
        message: '登录中...',
        duration: 0
      })
      this.$api.auth_index({ cellphone: this.phone, openid: this.$route.query.openid, code: this.vcode })
        .then(res => {
          this.$toast.clear()
          this.$toast.success('登录成功')
          // token
          if (res.data && res.data.access_token) {
            console.log('获取到access_token')
            const token = res.data.access_token
            let name = `${localStorage.getItem('appid')}-Access-Token`
            this.$store.commit('user/SET_TOKEN', saveToken(token,name))
          } else {
            console.error('没有access_token')
          }
          setTimeout(()=>{
            if (this.$route.query.redirect) {
              let obj = this.$route.query
              let redirect = this.$route.query.redirect
              delete obj.redirect
              this.$router.replace({ path: redirect,query: obj})
            } else {
              this.$router.replace({ path: '/home' })
            }
          },0)
        })
        .catch(err => {
          this.$toast.clear()
          this.$toast.fail(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>

  .header{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 250px;
    color: #fff;
    padding: 0 15px;
    // background-color: #fe4140;
    background: @linear-gradient-color;
    background-image: url('~@/assets/user_bg.png');
    background-size: 100% 100%;
    &-avatar{
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translateX(-50%);
      width: 116px;
      height: 116px;
      background: rgba(255,255,255, .5);
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 5px rgba(255,255,255, 1);
      &-img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
  .form{
    padding: 0 30px;
  }
  .btn-area{
    margin-top: 20px;
  }
  .container{
    box-sizing: border-box;

    height: 100vh;
    background-color: #fff;
    background-image: url(~@/assets/apply_bg.png);
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
</style>